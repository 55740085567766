<template>
  <div class="payment-domestic-detail">
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-7 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">Transaction detail</h3>
            </div>

            <div class="card-body min-vh" v-loading="loading">
              <el-descriptions
                class="margin-top"
                title="Detail"
                :column="1"
                border
                v-if="detailData"
              >
                <template slot="extra">
                  <el-tag
                    type="success"
                    v-if="detailData.status === 'approve'"
                    >{{ detailData.status }}</el-tag
                  >
                  <el-tag
                    type="danger"
                    v-if="detailData.status === 'decline'"
                    >{{ detailData.status }}</el-tag
                  >
                  <el-tag
                    type="info"
                    v-if="detailData.status === 'wait'"
                    >{{ detailData.status }}</el-tag
                  >
                </template>

                <el-descriptions-item label="CaseID">
                  <el-tag size="small" type="danger">{{ detailData.caseId }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="BankholderID">
                  <el-tag size="small">{{ detailData.bankholderId }}</el-tag>
                </el-descriptions-item>

                <el-descriptions-item
                  :label="
                    detailData.paymentType == 'payout'
                      ? 'Payee Name'
                      : 'Sender Name'
                  "
                >
                  {{ detailData.payeeName }}
                </el-descriptions-item>

                <el-descriptions-item label="Amount">
                  {{ detailData.amount }}
                  {{ detailData.currency.toUpperCase() }}
                </el-descriptions-item>
                <el-descriptions-item label="Payment Type">
                  <el-tag
                    size="small"
                    v-if="detailData.paymentType == 'payout'"
                    type="warning"
                    >{{ detailData.paymentType }}</el-tag
                  >
                  <el-tag
                    size="small"
                    v-if="detailData.paymentType == 'payin'"
                    >{{ detailData.paymentType }}</el-tag
                  >
                </el-descriptions-item>
                <el-descriptions-item label="Status">
                  <el-tag
                    size="small"
                    type="success"
                    v-if="detailData.status === 'approve'"
                    >{{ detailData.status }}</el-tag
                  >
                  <el-tag
                    size="small"
                    type="danger"
                    v-if="detailData.status === 'decline'"
                    >{{ detailData.status }}</el-tag
                  >
                  <el-tag
                    size="small"
                    type="info"
                    v-if="detailData.status === 'wait'"
                    >{{ detailData.status }}</el-tag
                  >
                  <el-tag size="small" v-if="detailData.status === 'OK'">{{
                    detailData.status
                  }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="Reference">
                  {{ detailData.reference }}
                </el-descriptions-item>
                <el-descriptions-item label="Reason" v-if="detailData.status !== 'approve'">
                  {{ detailData.reason }}
                </el-descriptions-item>
                <el-descriptions-item
                  label="Comment"
                  content-class-name="comment-content"
                  v-if="detailData && detailData.status !== 'approve'"
                >
                  <pre>{{ detailData.comment }}</pre>
                </el-descriptions-item>
                <el-descriptions-item label="Create">
                  {{ detailData.createDate }}
                </el-descriptions-item>
                <el-descriptions-item label="Update">
                  {{ detailData.updatedDate }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <h3 class="mb-0">Documents</h3>
            </div>

            <div class="card-body min-vh" v-loading="loading">
              <el-alert
                v-if="detailData && detailData.status !== 'approve'"
                title="Bank Compliance review and comments"
                type="warning"
                
                :closable="false"
                show-icon
              >
                <b>{{ detailData.reason }}</b><br>
                <pre v-if="detailData.comment">{{ detailData.comment }}</pre>
              </el-alert>

              <el-card class="box-card" shadow="never">
                <div slot="header" class="clearfix">
                  <span
                    ><i class="el-icon-upload"></i> Support documents source of
                    wealth
                  </span>
                </div>
                <div>
                  <el-upload
                    :action="uploadUrl()"
                    drag
                    :auto-upload="true"
                    :on-success="onSuccessUpload"
                    :file-list="fileList.sourceOfWealth"
                    accept=".png,.jpg,.jpeg,.doc,.docx,.xls,.xlsx,.pdf,.zip,.rar"
                    :headers="headerUpload"
                    name="transaction_files"
                    :data="{ type: 'sourceOfWealth' }"
                  >
                    <el-button size="small" type="primary"
                      >Click to upload</el-button
                    >

                    <div slot="file" slot-scope="{ file }">
                      <a class="el-upload-list__item-name">
                        <span class="file-download" @click="fileDownload(file)"
                        ><i class="el-icon-download"></i></span>
                        <i :class="fileIcon(file.contentType)"></i>
                        <span @click="filePreview(file)">{{ file.filename }}</span>
                      </a>
                    </div>
                  </el-upload>
                </div>
              </el-card>
              <el-card class="box-card" shadow="never">
                <div slot="header" class="clearfix">
                  <span
                    ><i class="el-icon-upload"></i> Support purpose of payment
                    documents</span
                  >
                </div>
                <div>
                  <el-upload
                    :action="uploadUrl()"
                    drag
                    :auto-upload="true"
                    :on-success="onSuccessUpload"
                    :file-list="fileList.purposeOfPayment"
                    accept=".png,.jpg,.jpeg,.doc,.docx,.xls,.xlsx,.pdf,.zip,.rar"
                    :headers="headerUpload"
                    name="transaction_files"
                    :data="{ type: 'purposeOfPayment' }"
                  >
                    <el-button size="small" type="primary"
                      >Click to upload</el-button
                    >

                    <div slot="file" slot-scope="{ file }">
                      <a class="el-upload-list__item-name">
                        <span class="file-download" @click="fileDownload(file)"
                        ><i class="el-icon-download"></i></span>
                        <i :class="fileIcon(file.contentType)"></i>
                        <span @click="filePreview(file)">{{ file.filename }}</span>
                      </a>
                    </div>
                  </el-upload>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible">
      <img style="width: 100%; height: auto" :src="dialogImageUrl" />
    </el-dialog>
    
  </div>
</template>

<script>
import Auth from "@/api/auth";
import PaymentApi from "@/api/payment";
import Utils from "@/utils";
export default {
  data() {
    return {
      loading: false,
      loadingDocs: false,
      detailData: null,
      dialogImageUrl: "",
      dialogVisible: false,
      serverBaseAPI: process.env.VUE_APP_SERVER_BASE_API + process.env.VUE_APP_SERVER_BASE_API_VERSION,
      fileList: {
        purposeOfPayment: [],
        sourceOfWealth: [],
      },
      headerUpload: {
        authorization: Auth.getToken(),
      },
      contentType: {
        xlsx: "application/vnd.openxmlformats-officedocument.spre",
        docx: "application/vnd.openxmlformats-officedocument.word",
        pdf: "application/pdf",
        png: "image/png",
        jpg: "image/jpeg",
        zip: "application/zip",
        rar: "application/x-rar-compressed",
      },
    };
  },
  methods: {
    getTransactionDetail() {
      this.loading = true;
      PaymentApi.getPaymentDomesticDetail(this.$route.params.paymentuid).then(
        ({ result, data }) => {
          this.loading = false;
          if (result) {
            this.detailData = data;
            this.getTransactionDocs()
          }
        }
      );
    },
    onSuccessUpload() {
      this.getTransactionDocs();
    },
    uploadUrl(){
      return `${this.serverBaseAPI}/v1/payment/pending-domestic/${this.$route.params.paymentuid}/documents`
    },
    mediaUrl(filename){
      return `${this.serverBaseAPI}/v1/payment/pending-domestic/${this.$route.params.paymentuid}/media/${filename}`;
    },
    fileIcon(fileType) {
      if (
        fileType === this.contentType.png ||
        fileType === this.contentType.jpg
      ) {
        return "el-icon-picture";
      } else {
        return "el-icon-document";
      }
    },
    fileDownload(file) {
      const link = document.createElement("a");
      link.href = this.mediaUrl(file.filename);
      link.download = file.filename;
      link.target = "_blank";
      link.click();
    },
    filePreview(file) {
      if (
        file.contentType === this.contentType.png ||
        file.contentType === this.contentType.jpg
      ) {
        this.dialogImageUrl = this.mediaUrl(file.filename);
        this.dialogVisible = true;
      } else {
        this.fileDownload(file);
      }
    },
    getTransactionDocs() {
      this.loadingDocs = true;
      this.fileList.purposeOfPayment = [];
      this.fileList.sourceOfWealth = [];
      PaymentApi.getPaymentDomesticDocument(this.$route.params.paymentuid).then(({ result, data }) => {
        this.loadingDocs = false;
        if (result) {
          this.fileList.purposeOfPayment = data.purposeOfPayment;
          this.fileList.sourceOfWealth = data.sourceOfWealth;
        }
      });
    },
    dateFormat(date) {
      return Utils.dateFormat(date, "YYYY-MM-DD hh:mm:ss");
    },
  },
  mounted() {
    this.getTransactionDetail();
  },
};
</script>

<style lang="scss">
.payment-domestic-detail {
  .el-card{
    margin-top: 16px;
  }

  .el-tag {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 14px;
  }

  .comment-content pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: inherit;
    margin: 0;
    color: #606266;
    font-size: 14px;
  }

  .el-alert__description pre{
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: inherit;
    margin: 0;
    color: #E6A23C;
    font-size: 12px;
  }

  .el-upload-dragger {
    width: auto;
    height: auto;
    border: none;
  }
  .el-upload--picture-card {
    line-height: 1 !important;
    position: relative;
  }
}
</style>